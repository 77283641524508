body{
    margin:0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color:#80BDE3;
}

/* centre content with flex */

.container, .searchInput, .weatherContainer{
    justify-content: center;
    align-items: center;
}

.container,.searchInput,
.weatherContainer,
.searchBar,
.dayOfWeek,
.dayForecast,
.buttonContainer
{
    display: flex;
}

.searchBarContainer{
    width: 88%;
}

.imageOverlay{
position:fixed;
width: 100%;
height: 100%;
top:0;
left:0;
right:0;
bottom:0;
background-color: rgba(0,0,0,0.5);
z-index:1;
overflow: auto;
}

.backgroundImg{
    height: 100vh;
    background-repeat: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: 'Roboto', sans-serif;
    image-rendering: crisp-edges;
 image-rendering: -moz-crisp-edges;
 image-rendering: -o-crisp-edges;
 image-rendering: -webkit-optimize-contrast;
 -ms-interpolation-mode: nearest-neighbor;
}

.container{
    flex-direction: column;
    justify-content: space-between;
    gap:1rem;
}


.searchBar{
    margin: 1rem 0 0 0;
    width: 90%;

}
.searchBar, .searchButton{
    height: 2.5rem;
}

.dayOfWeek{
   align-items: center;
   justify-content: flex-start;
   gap:1rem;
   height: fit-content;
   width: cover;
   padding: 0 1.5rem 0 1.5rem;
position: relative;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1000;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.day{
   align-self: flex-end;
}

.searchInput{
    align-items: baseline;
    flex: 1;
    justify-content: center;
    text-align: center;
}

.weatherContainer{
    background-color: white;
    opacity: 70%;
    border-radius: 5px;
    width: 87%;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: fit-content;


}

.dummyImage{
    height: 15px;
    width: 15px;
    background-color: blueviolet;
}

.dayForecast{
    flex-direction: row;
    gap:2.125rem;

    align-items:center;
    justify-content: space-around;
    padding: 0 1.5rem 0 1.5rem;
    position: relative;
}
.borderBottom{
    position:absolute;
    border-bottom: 1px solid black;
    top:90%;
    width: 90%;
    text-align: center;
    right: 1rem;
}

.buttonContainer{
    gap:1rem;
}

.creditCaption{
    width: fit-content;
    padding: 0.5rem;
    align-self: inherit;
    position: relative;

}

.creditCaption>*{
    margin: 0.1rem;
    align-self: flex-start;
}


.circle{
    height: 1rem;
    width: 1rem;
    background-color: white;
    border-radius: 80%;
    cursor: pointer;
}

/* Test Pagination Style */
.swiper {
    width: 95%;
    height: cover;
    padding: 0.2rem;
    height:24rem;
  }

  .swiper-slide {
    text-align: center;
    font-size: 1rem;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
    bottom:1rem;

  }

  .swiper-slide img {
    display: block;
    width: cover;
    height: cover;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }


 .currentWeatherIcon{
    height:50pixels;
    width: 50pixels;
 }

 .currentWeather>*{
    margin: 0.2rem
 }

  .Tabs, .nav, .currentTime{
    display: none;
  }

  /* Iphone SE screenSizing */

  @media screen and (max-width:375px) {
    .currentWeather>* {
        margin:0.1rem 0 0 0;
    }
    .container{
        gap:0.75rem;
    }
    .swiper-slide{
        font-size: 0.9rem;
    }
    .swiper{
        height:21rem;
    }
    .currentDummy{
        height: 30px;
        width: 30px;
    }
  }

  /* Start of Tablet Sizing */

  @media screen and (min-width:800px) {
   .currentWeather{
    width:91%
   }
   .dayForecast, .dayOfWeek{
    width:100%;
   }
  }



  @media screen and (min-width:810px) {
    .container{
        gap:2rem;
    }
    .swiper{
        width:90%

    }
    .creditCaption{
        padding:1rem;
    }

  }
  @media screen and (min-width:1280px){
    .container{
        display:grid;
        overflow: clip;
        grid-template-columns: 20% 35% 32% 13%;
        grid-template-rows: 5rem 20rem 4rem 25rem;

    }
    .searchBarContainer{
        grid-area: 2/2/1/4;
        justify-self: center;
    }
    .currentWeather{
        grid-area:2/3/3/4
    }
    .swiper{
        display: none;
    }

    .nav{
        grid-area: 3/2/3/4;
        display: flex;
        flex-direction: row;
    }

    .Tabs{
        display: block;
        grid-area: 4/2/4/4;
        width: 97.5%;
        height: cover;

    }


    .creditCaption{
        grid-area: 4/1/4/4;
        align-self: flex-end;
        margin-left: 2rem;
    }

    .currentTime{
        display: flex;
        grid-area: 2/2/2/3;
        min-height: 290px;
    }
    .currentTime, .currentWeather{
        width:95%;
        height:cover
    }
    .borderBottom{
        width: 97%;
    }



  }